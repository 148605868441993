import "./bootstrap"
import "stellar-ui/dist/style.css"
import "../css/app.css"
import { createApp, h, DefineComponent } from "vue"
import { createPinia } from "pinia"
import { createInertiaApp, router } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m"
// @ts-ignore
import * as Sentry from "@sentry/vue"

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel"

const pinia = createPinia()

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
        })

        app.use(plugin)
            // @ts-ignore
            .use(ZiggyVue, Ziggy)
            .use(pinia)
            .mount(el)
    },
    progress: {
        color: "#4B5563",
    },
})

// if (import.meta.env.PROD && import.meta.env.VITE_APP_ENV === 'production') {
router.on("navigate", (event) => {
    window.dataLayer = window.dataLayer || []

    const pageProps = event.detail.page.props

    if (pageProps.flash && pageProps.flash.dataLayer) {
        const dataLayerData = pageProps.flash.dataLayer

        if (typeof dataLayerData === "object" && dataLayerData !== null && typeof dataLayerData.event === "string") {
            window.dataLayer.push(dataLayerData)
            console.log("Pushed dataLayer event from flash:", window.dataLayer) // Good to keep logging in development
        } else {
            console.warn("Invalid dataLayer format in flash:", dataLayerData) // Warn if data format is incorrect
        }
    }
})
// }
